import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'

import MusicImage from '../../images/sound-cover.jpg'
import Flufhaus from '../../music/FlufHaus_MixTape.mp3'
  

export default function MusicPlayer() {

  const communityPlayList = [
    {
      name: 'Fluf Haus Mix',
      singer: 'No Credits Left (@0CreditsLeft)',
      cover: 'https://i.vimeocdn.com/video/1360198535-31d8bf7318cbca013b8001df8a697de2e9121499024e86b5e5f73d199bce086b-d?mw=1200&mh=675',
      musicSrc: Flufhaus,
    },
    {
      name: 'Messiah',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/28_Messiah.mp4'
    },
    {
      name: 'I Love Fluf',
      singer: 'Young & Sick',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/I_Love_FLUF.mp4'
    },
    {
      name: 'Let The Story Begin',
      singer: 'The Beastie Bears',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/The_Beastie_Bears.mp4'
    },
    {
      name: 'Beyond The Gates',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/16_Beyond_the_Gates.mp4'
    },
    {
      name: 'Lucky Foot',
      singer: 'Mike Shinoda',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/Lucky_Foot_LOW_LOW_RES.mp4'
    },
    {
      name: 'Ear Wobbler',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/12_Ear_Wobbler.mp4'
    },
    {
      name: 'Follow You',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/03_Follow_You.mp4'
    },
    {
      name: 'How You Feel',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/24_How_You_Feel.mp4'
    },
    {
      name: 'Floating or Flying',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/13_Floating_or_Flying.mp4'
    },
    {
      name: 'FLUF You Up',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/06_FLUF_You_Up.mp4'
    },
    {
      name: 'Dubhop',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/04_Dobhop.mp4'
    },
    {
      name: 'Electric Carrots',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/15_Electric_Carrots.mp4'
    },
    {
      name: 'Let It Go',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/27_Let_It_Go.mp4'
    },
    {
      name: 'How Could You',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/23_How_Could_You.mp4'
    },
    {
      name: 'Space Fluffle',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/09_Space%20Fluffle.mp4'
    },
    {
      name: 'Back In The Burrow',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/11_Back_in_the_Burrow.mp4'
    },
    {
      name: 'NFT (Instrumental)',
      singer: 'AngelBaby',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/NFT%20(Instrumental).mp4'
    },
    {
      name: 'Fluf Ocean',
      singer: 'Dude Skywalker',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/FLUF_Ocean_Low.mp4'
    },
    {
      name: 'Higher',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/22_Higher.mp4'
    },
    {
      name: 'Desert Digging',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/07_Desert_Digging.mp4'
    },
    {
      name: 'Wouldn\'t It Be',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/30_Wouldn_t_It_Be.mp4'
    },
    {
      name: 'Reggae Rabbits',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/19_Reggae_Rabbits.mp4'
    },
    {
      name: 'Coffee and Carrots',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/02_Coffee_and_Carrots.mp4'
    },
    {
      name: 'What the FLUF',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/05_What_the_FLUF.mp4'
    },
    {
      name: 'Shadows',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/29_Shadows.mp4'
    },
    {
      name: 'L.O.V.E',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/25_L_O_V_E_.mp4'
    },
    {
      name: 'I Like to Party',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/18_I_Like_to_Party.mp4'
    },
    {
      name: 'Stardust',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/31_Stardust.mp4'
    },
    {
      name: 'Latin Fiesta',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/26_Latin_Fiesta.mp4'
    },
    {
      name: 'Rabbiton',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/20_Rabbiton.mp4'
    },
    {
      name: 'Disco Rabbit',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/08_Disco_Rabbit.mp4'
    },
    {
      name: 'All I Need',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/21_All_I_Need.mp4'
    },
    {
      name: 'Run Rabbit',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/01_Run_Rabbit.mp4'
    },
    {
      name: 'Hop On By',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/14_Hop_On_By.mp4'
    },
    {
      name: 'Hop With Me',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/17_Hop_With_Me.mp4'
    },
    {
      name: 'Eating Carrots in the Dark',
      singer: 'Fairfields',
      cover: MusicImage,
      musicSrc: 'https://fluf-assets.s3.eu-central-1.amazonaws.com/music_low/10_Eating_Carrots_in_the_Dark.mp4'
    },
  ]

  const options = {
    theme: "dark",
    bounds: 'body',
    defaultPlayMode: 'orderLoop',
    autoPlay: false,
    showDownload: false,
    showThemeSwitch: false,
    showLyric: false,
    spaceBar: false,
    remove: false,
    drag: false,
    // preload: isMobile ? false : true,
    defaultPosition: {
      left: 20,
      bottom: 20,
    },
    glassBg: false,
    // remember: true,
    audioLists: communityPlayList
  }

  return (
    <ReactJkMusicPlayer {...options} />
  )
}