import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
// import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import { createContext, useCallback, useEffect, useState } from "react";
import { db, projectAuth } from "../../firebase/config";
import {useAuthState} from 'react-firebase-hooks/auth';
import moment from 'moment';

export const SiteContext = createContext();

// const siteReducer = (state, action) => {
//   switch (action.type) {
//     case 'ADD_STATS':
//       return { ...state, color: action.payload }
//     case 'SET_GAS_PRICE':
//       return { ...state, gasPrice: action.payload }      
//     case 'SET_ERRORS':
//       return { ...state, errors: action.payload }      
//     case 'SET_PROJECT_STATS':
//       return { ...state, projectStatsData: action.payload }      
//     case 'SET_COLLECTIONS_PENDING':
//       return { ...state, collectionsPending: action.payload }      
//     case 'SET_COLLECTIONS':
//       return { ...state, collectionsData: action.payload }      
//     case 'SET_META_PENDING':
//       return { ...state, metaPending: action.payload }      
//     case 'SET_META':
//       return { ...state, metaData: action.payload }
//     case 'SET_POSTS_PENDING':
//       return { ...state, postsPending: action.payload }      
//     case 'SET_POSTS':
//       return { ...state, postsData: action.payload }      
//     case 'SET_COMPANIES_PENDING':
//       return { ...state, companiesPending: action.payload }      
//     case 'SET_COMPANIES':
//       return { ...state, companiesData: action.payload }      
//     case 'SET_TOKENS_PENDING':
//       return { ...state, tokensPending: action.payload }      
//     case 'SET_TOKENS':
//       return { ...state, tokensData: action.payload }      
//     case 'SET_COUNTDOWN_PENDING':
//       return { ...state, countdownPending: action.payload }      
//     case 'SET_COUNTDOWN':
//       return { ...state, countDownData: action.payload }      
//     case 'SET_TEAM_PENDING':
//       return { ...state, theTeamPending: action.payload }      
//     case 'SET_TEAM':
//       return { ...state, theTeamData: action.payload }
//     default:
//       return state
//   }
// }


export const SiteProvider = ({ children }) => {



  const [user] = useAuthState(projectAuth);

  // const setGasPrice = (gas) => {
  //   dispatch({ type: 'SET_GAS_PRICE', payload: gas })
  // }

  // const setErrors = (errors) => {
  //   dispatch({ type: 'SET_ERRORS', payload: errors })
  // }

  // const setProjectStatsData = (projectStats) => {
  //   dispatch({ type: 'SET_PROJECT_STATS', payload: projectStats })
  // }

  // const setCollectionsPending = (pending) => {
  //   dispatch({ type: 'SET_COLLECTIONS_PENDING', payload: pending })
  // }
  // const setCollectionsData = (data) => {
  //   dispatch({ type: 'SET_COLLECTIONS', payload: data })
  // }

  // const setMetaPending = (pending) => {
  //   dispatch({ type: 'SET_META_PENDING', payload: pending })
  // }
  // const setMetaData = (data) => {
  //   dispatch({ type: 'SET_META', payload: data })
  // }

  // const setPostsPending = (pending) => {
  //   dispatch({ type: 'SET_POSTS_PENDING', payload: pending })
  // }
  // const setPostsData = (data) => {
  //   dispatch({ type: 'SET_POSTS', payload: data })
  // }

  // const setCompaniesPending = (pending) => {
  //   dispatch({ type: 'SET_COMPANIES_PENDING', payload: pending })
  // }
  // const setCompaniesData = (data) => {
  //   dispatch({ type: 'SET_COMPANIES', payload: data })
  // }

  // const setTokensPending = (pending) => {
  //   dispatch({ type: 'SET_TOKENS_PENDING', payload: pending })
  // }
  // const setTokensData = (data) => {
  //   dispatch({ type: 'SET_TOKENS', payload: data })
  // }

  // const setCountdownPending = (pending) => {
  //   dispatch({ type: 'SET_COUNTDOWN_PENDING', payload: pending })
  // }
  // const setCountDownData = (data) => {
  //   dispatch({ type: 'SET_COUNTDOWN', payload: data })
  // }

  // const setTheTeamPending = (pending) => {
  //   dispatch({ type: 'SET_TEAM_PENDING', payload: pending })
  // }
  // const setTheTeamData = (data) => {
  //   dispatch({ type: 'SET_TEAM', payload: data })
  // }

  // const [state, dispatch] = useReducer(siteReducer, {
  //   gasPrice: [], 
  //   errors: [],
    
  //   projectStatsData: [],
    
  //   collectionsPending: false,
  //   collectionsData: [], 

  //   metaPending: false,
  //   metaData: [],

  //   postsPending: false,
  //   postsData: [],

  //   companiesPending: false,
  //   companiesData: [],

  //   tokensPending: false,
  //   tokensData: [],

  //   countdownPending: false,
  //   countDownData: [],

  //   theTeamPending: false,
  //   theTeamData: [],
  // });

  const [playerContext, setPlayerContext] = useState(null);

  const [errors, setErrors] = useState([]);
  const [projectStatsData, setProjectStatsData] = useState([]);
  const [statsDate, setStatsDate] = useState('');
  const [walletCount, setWalletCount] = useState('');
  const [walletCountAll, setWalletCountAll] = useState('');

  const [tokenStatsData, setTokenStatsData] = useState([]);

  const [tagPending, setTagPending] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [collectionsPending, setCollectionsPending] = useState(false);
  const [collectionsData, setCollectionsData] = useState([]);

  // const [floorPriceCollections, setFloorPriceCollections] = useState([]);
  // const [statsAdded, setStatsAdded] = useState(false);
  
  const [metaPending, setMetaPending] = useState(false)
  const [metaData, setMetaData] = useState([]);
  
  const [postsPending, setPostsPending] = useState(false)
  const [postsData, setPostsData] = useState([]);
  
  const [futureverseTeamMedia, setFutureverseTeamMedia] = useState([]);
  const [futureverseTeamMediaPending, setFutureverseTeamMediaPending] = useState([]);
  const [futureverseTeam, setFutureverseTeam] = useState([]);
  const [futureverseTeamPending, setFutureverseTeamPending] = useState([]);

  const [companiesPending, setCompaniesPending] = useState(false)
  const [companiesData, setCompaniesData] = useState([]);

  const [tokensPending, setTokensPending] = useState(false)
  const [tokensData, setTokensData] = useState([]);

  const [countdownPending, setCountdownPending] = useState(false)
  const [countDownData, setCountDownData] = useState([]);

  const [theTeamPending, setTheTeamPending] = useState(false)
  const [theTeamData, setTheTeamData] = useState([]);


  useEffect(() => {
    let abortController = new AbortController();

    setTagPending(true)
    
    const ref = collection(db, 'tags');
    const q = query(ref);

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setTagPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setTagData(results)
        setTagPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setTagPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  const fetchCollections = useCallback(async () => {
    if (collectionsData && collectionsData.length > 0) {

      setProjectStatsData([]);

      const options = {method: 'GET', headers: {Accept: 'application/json'}};

      const colData = await fetch(`https://api.theprawns.xyz/api/v1/stats`, options);
      const colJson = await colData.json();

      const statsIntersection = colJson.data.Stats.filter(collection => collectionsData.some(project => project.statsMergeID === collection.id ));

      const sDate = moment(colJson.data.Date);

      setStatsDate(sDate)
      setProjectStatsData(statsIntersection)
      setWalletCount(colJson.walletCount)

      const allWallets = await fetch(`https://api.theprawns.xyz/api/v1/stats/unique-wallets/all`, options);
      const allWalletsJson = await allWallets.json();

      setWalletCountAll(allWalletsJson.result)

    }

  }, [collectionsData])

  const fetchTokenStats = useCallback(async () => {
    if (tokensData && tokensData.length > 0) {

      setTokenStatsData([]);

      const options = {method: 'GET', headers: {Accept: 'application/json'}};

      const tokenData = await fetch(`https://api.theprawns.xyz/api/v1/tokens`, options);
      const tokenJson = await tokenData.json();

      const tokenIntersection = tokenJson.data.Tokens.filter(t => tokensData.some(token => token.contractSymbol === t.symbol || "ETH" === t.symbol ));

      setTokenStatsData(tokenIntersection)

    }

  }, [tokensData])


  useEffect(() => {
    let abortController = new AbortController();
    setCollectionsPending(true)

    const ref = collection(db, 'collections');
    // const q = query(ref, where('isActive', "==", true), orderBy('order'));
    const q = query(ref, orderBy('order'));
    
    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setCollectionsPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })          
        })
        setCollectionsData(results)
        setCollectionsPending(false)

      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setCollectionsPending(false)
    })
    return () => abortController.abort();  
  }, [user])

  useEffect(() => {
    let abortController = new AbortController();

    setMetaPending(true)

    const ref3 = collection(db, 'collectionsMetaData');
    const q3 = query(ref3, orderBy('date', "desc"), limit(100));

    getDocs(q3).then(snapshot => {
      // console.log(snapshot)
      if (snapshot.empty) {
        setMetaPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setMetaData(results)
        setMetaPending(false)
      }

      }).catch(err => {
      setErrors(err => [...err, err.message])
      setMetaPending(false)
    })
    return () => abortController.abort();  

  }, [user])


  useEffect(() => {
    let abortController = new AbortController();

    setPostsPending(true)
    
    const ref = collection(db, 'posts');
    const q = query(ref, orderBy('date', "desc"), limit(100));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setPostsPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setPostsData(results)
        setPostsPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setPostsPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  useEffect(() => {
    let abortController = new AbortController();

    setFutureverseTeamMediaPending(true)
    
    const ref = collection(db, 'futureverseFoundersMedia');
    const q = query(ref, orderBy('date', "desc"), limit(100));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setFutureverseTeamMediaPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setFutureverseTeamMedia(results)
        setFutureverseTeamMediaPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setFutureverseTeamMediaPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  useEffect(() => {
    let abortController = new AbortController();

    setFutureverseTeamPending(true)
    
    const ref = collection(db, 'futureverseTeam');
    const q = query(ref, orderBy('order', "asc"), limit(100));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setFutureverseTeamPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setFutureverseTeam(results)
        setFutureverseTeamPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setFutureverseTeamPending(false)
    })
    return () => abortController.abort();  

  }, [user])
  
  useEffect(() => {
    let abortController = new AbortController();

    setCompaniesPending(true)
    
    const ref = collection(db, 'companies');
    const q = query(ref, orderBy('name', "desc"));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setCompaniesPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setCompaniesData(results)
        setCompaniesPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setCompaniesPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  useEffect(() => {
    let abortController = new AbortController();

    setTokensPending(true)
    
    const ref = collection(db, 'tokens');
    const q = query(ref, orderBy('name', "desc"));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setTokensPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setTokensData(results)
        setTokensPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setTokensPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  useEffect(() => {
    let abortController = new AbortController();

    setTheTeamPending(true)
    
    const refCo = collection(db, 'theTeam');
    const q = query(refCo, orderBy('order'));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setTheTeamPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setTheTeamData(results)
        setTheTeamPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setTheTeamPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  useEffect(() => {
    let abortController = new AbortController();

    setCountdownPending(true)
    
    const ref = collection(db, 'countDownTimer');
    const q = query(ref, where("isActive", "==", true), orderBy('order'));

    getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        setCountdownPending(false)
      } else {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() })
        })
        setCountDownData(results)
        setCountdownPending(false)
      }

    }).catch(err => {
      setErrors(err => [...err, err.message])
      setCountdownPending(false)
    })
    return () => abortController.abort();  

  }, [user])

  useEffect(() => {
    // call the function
    let abortController = new AbortController();

    fetchCollections()
    fetchTokenStats()
    const collectionInterval = setInterval(() => {
      fetchTokenStats()
      fetchCollections()
    }, 1000 * 60 * 15);

    return () => {
      abortController.abort()
      clearInterval(collectionInterval);
    }
      // make sure to catch any error
  }, [fetchCollections, fetchTokenStats])

  return (
    <SiteContext.Provider value={
      {

        fetchCollections: fetchCollections,

        playerContext: playerContext,
        setPlayerContext: setPlayerContext,
        
        errors: errors,
        setErrors: setErrors,
      
        setTagData: setTagData,
        tagData: tagData,

        tagPending: tagPending,
        setTagPending: setTagPending,

        setProjectStatsData: setProjectStatsData,
        projectStatsData: projectStatsData,
        statsDate: statsDate,
        walletCount: walletCount, 
        walletCountAll: walletCountAll,

        setTokenStatsData: setTokenStatsData,
        tokenStatsData: tokenStatsData,
        
        setCollectionsPending: setCollectionsPending,
        setCollectionsData: setCollectionsData,

        collectionsPending: collectionsPending,
        collectionsData: collectionsData, 

        setMetaPending: setMetaPending,
        metaPending: metaPending,

        setMetaData: setMetaData,
        metaData: metaData,

        setPostsPending: setPostsPending,
        postsPending: postsPending,

        setPostsData: setPostsData,        
        postsData: postsData,

        setCompaniesPending: setCompaniesPending,
        companiesPending: companiesPending,

        futureverseTeamMedia: futureverseTeamMedia,
        setFutureverseTeamMedia: setFutureverseTeamMedia,
        
        futureverseTeamMediaPending: futureverseTeamMediaPending,
        setFutureverseTeamMediaPending: setFutureverseTeamMediaPending,
        
        futureverseTeam: futureverseTeam,
        setFutureverseTeam: setFutureverseTeam,
        
        futureverseTeamPending: futureverseTeamPending,
        setFutureverseTeamPending: setFutureverseTeamPending,

        setCompaniesData: setCompaniesData,
        companiesData: companiesData,

        setTokensPending: setTokensPending,
        tokensPending: tokensPending,

        setTokensData: setTokensData,
        tokensData: tokensData,

        setCountdownPending: setCountdownPending,
        countdownPending: countdownPending,

        setCountDownData: setCountDownData,
        countDownData: countDownData,

        setTheTeamPending: setTheTeamPending,
        theTeamPending: theTeamPending,

        setTheTeamData: setTheTeamData,
        theTeamData: theTeamData,      

      }
    }>
      {children}
    </SiteContext.Provider>
  )
}