import { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { device } from '../../theme/MediaQueries';
import ThemeDefault from '../../theme/ThemeDefault';

import Prawn from '../../images/SVG/prawn.svg';
// import Goblin from '../../images/goblin-logo.png';
// import { HashLink } from 'react-router-hash-link';

import websiteMenuTopNav from '../../websiteMenuTopNav';
import InfoBar from '../InfoBar/InfoBar';
// import ReactTooltip from 'react-tooltip';
// import './Header.css'

const HeaderDiv = styled.div`
	position: fixed;
	z-index: 9;
	width: 100%;
	background: ${ThemeDefault.trueBlack};
	top: 0;

	& + section,
	& + div {
		margin-top: 150px;
	}
	& .contribute {
		padding: 1.6rem 0.8rem;
		background: ${ThemeDefault.blackDarker};
		width: 100%;
		font-size: 1.2rem;
		line-height: 1.2;
		font-weight: 400;
		letter-spacing: 0.4px;
		color: ${ThemeDefault.primary};
		word-wrap: break-word;
		& span {
			font-weight: 700;
		}
	}
`;

const NavWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: space-between;
	padding: 1.6rem 1.6rem;
	display: none;

	@media ${device.MXlg} {
		display: flex;
	}
`;

const MenuButton = styled.button`
	cursor: pointer;
	color: ${ThemeDefault.white};
	font-size: 1.2rem;
	padding: 0.6rem 1rem;
	border: 1px solid ${ThemeDefault.white};
	border-radius: 0.8rem;
	display: inline-block;
	background: transparent;
	font-family: inherit;
`;

const MainNavWrap = styled.div`
	width: 100%;

	& .close {
		display: none;
	}

	@media ${device.MXlg} {
		display: none;
		& .close {
			display: none;
		}
		&.open {
			display: flex;
			position: fixed;
			width: 100vw;
			top: 0;
			left: 0;
			padding: 30px;
			background: #000;
			min-height: 100vh;
			min-height: -webkit-fill-available;
			height: 100%;
			align-content: flex-start;
			align-items: flex-start;
			padding-top: 60px;
			overflow: scroll;
			z-index: 9;

			& .close {
				display: block;
				position: fixed;
				top: 1.6rem;
				right: 1.6rem;
				line-height: 1.5;
				z-index: 999;
			}

			& > ul {
				display: flex;
				flex-direction: column;
				/* padding: 1.6rem; */

				& li {
					margin: 0.4rem;

					@media ${device.MXlg} {
						text-align: left;
					}
					& a {
						font-size: 2rem;
					}
				}
			}
		}
	}
`;

const MainNav = styled.ul`
	width: 100%;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-end;
	padding: 1.6rem 0;
	list-style: none;

	@media ${device.MXlg} {
		justify-content: flex-end;
	}

	& li:last-child .subMenu {
		left: auto;
		right: -0.4rem;
		transform: translate(0%, 100%);
		@media ${device.MXlg} {
			transform: none;
			right: 0;
		}

		& li {
			text-align: right;
			@media ${device.MXlg} {
				text-align: left;
			}
		}
	}
`;

const CustomNavLink = styled(NavLink)`
	position: relative;
	transition: 300ms;
	color: ${ThemeDefault.white};
	cursor: pointer;

	&:after {
		content: '';
		position: absolute;
		width: 0;
		height: 1px;
		bottom: -0.4rem;
		background: ${ThemeDefault.white};
		left: 50%;
		transform: translate(-50%, -50%);
		transition: 300ms;

		@media ${device.MXlg} {
			display: none;
		}
	}

	&.active {
		color: ${ThemeDefault.primary};

		&:after {
			width: 25px;
			background: ${ThemeDefault.primary};
		}
	}

	&:hover {
		color: ${ThemeDefault.white};

		&:after {
			width: 90%;
			background: ${ThemeDefault.white};
		}
	}
`;

// const CustomSubHashNavLink = styled(HashLink)`
//   position: relative;
//   transition: 300ms;
//   color: ${ThemeDefault.white};
//   cursor: pointer;
//   @media ${device.MXlg} {
//   text-align: left;
//   }

//   &.active {
//     color: ${ThemeDefault.primary};
//   }

//   &:hover {
//     color: ${ThemeDefault.white};
//   }
// `

const CustomSubNavLink = styled(NavLink)`
	position: relative;
	transition: 300ms;
	color: ${ThemeDefault.white};
	cursor: pointer;
	@media ${device.MXlg} {
		text-align: left;
	}

	&.active {
		color: ${ThemeDefault.primary};
	}

	&:hover {
		color: ${ThemeDefault.white};
	}
`;

const LogoWrapDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	flex-shrink: 0;

	&.mobile {
		display: none;
	}

	@media ${device.MXlg} {
		&.mobile {
			display: flex;
			width: 70%;
		}
		&.desktop {
			display: none;
		}
	}

	& a {
		text-decoration: none;
		font-weight: 700;
		color: ${ThemeDefault.white};
		display: flex;
		align-items: center;
	}
	& img {
		width: 32px;
		margin-right: 0.8rem;
	}
`;

const MenuWrapDiv = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.2rem;

	@media ${device.MXlg} {
		&.closed {
			height: auto;
		}
		&.open {
			height: -webkit-fill-available;
		}
	}
`;

const MenuItem = styled.li`
	font-size: var(--text-s);
	margin: 0 0.4rem;
	position: relative;

	@media ${device.MXlg} {
		margin: 0 !important;
		margin-bottom: 1.8rem !important;
	}

	& a {
		transition: 300ms;
		text-decoration: none;
		padding: 0.4rem 0.6rem;
		cursor: pointer;
	}

	&.has-dropdown:hover .subMenu {
		display: flex;
	}
`;

const SubMenuItem = styled.li`
	font-size: var(--text-s);
	margin: 1.2rem 0.4rem;
	position: relative;

	@media ${device.MXlg} {
		text-align: left;
		margin: 0 !important;
		padding: 0 !important;
		text-align: left;
	}

	& a {
		transition: 300ms;
		text-decoration: none;
		padding: 0.4rem 0.6rem;
		cursor: pointer;
		font-size: 1.4rem !important;

		@media ${device.MXlg} {
			font-size: 1.2rem !important;
			padding: 0 0.6rem;
		}
	}
`;
const SubMenuWrap = styled.div`
	left: 50%;
	transform: translate(-50%, 100%);
	position: absolute;
	display: none;
	padding: 0;
	margin: 0;
	bottom: 0;
	width: auto;
	white-space: nowrap;
	padding: 1.3rem 0 0 0;
	box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
		0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
		0px 22.3px 17.9px rgba(0, 0, 0, 0.042), 0px 41.8px 33.4px rgba(0, 0, 0, 0.05),
		0px 100px 80px rgba(0, 0, 0, 0.07);
	grid-gap: 0.4rem 0.8rem;
	background: #070707;

	@media ${device.MXlg} {
		background: transparent;
		display: flex;
		position: relative;
		font-size: 75%;
		transform: none;
		left: 0;
		top: 0;
		padding: 0;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	& .subMenu-title {
		margin: 1.2rem;
		text-align: left;
		font-size: 1rem;
		text-transform: uppercase;
		color: ${ThemeDefault.secondary};
		letter-spacing: 0.5px;
		font-weight: 700;

		@media ${device.MXlg} {
			margin-left: 0.6rem;
		}
	}
	@media ${device.MXsm} {
		& > div {
			width: 100%;
		}
	}
`;

const SubMenu = styled.ul`
	background: ${ThemeDefault.blackDarker};
	padding: 0;
	margin: 0;
	flex-direction: row;
	align-content: center;
	justify-content: flex-end;
	padding: 0.4rem 0.4rem;
	list-style: none;

	& .SubMenuItem:first-of-type {
		margin-top: 0 !important;
	}

	@media ${device.MXlg} {
		background: transparent;
		padding: 0 !important;
		margin-bottom: 0.8rem;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 0.4rem 0.8rem;
		min-width: 30%;
	}
`;

export default function Header() {
	const [menuOpenStatus, setMenuOpenStatus] = useState(false);

	const headerDivEl = useRef(null);

	const openMenu = () => {
		document.body.style.overflow = 'hidden';
		setMenuOpenStatus(true);
	};

	const closeMenu = () => {
		document.body.style.overflow = 'unset';
		setMenuOpenStatus(false);
	};

	// const [toolTipText, setToolTipText] = useState(
	// 	'Click To Copy: 0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f',
	// );

	// const changeToolTipText = () => {
	// 	navigator.clipboard.writeText('0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f');
	// 	setToolTipText('Copied...');
	// 	setTimeout(() => {
	// 		setToolTipText('Click To Copy: 0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f');
	// 	}, 1500);
	// };

	const scrollSticky = (event) => {
		if (window.scrollY < headerDivEl.current.children.item(0).clientHeight) {
			headerDivEl.current.style.top = `${-window.scrollY}px`;
		} else {
			headerDivEl.current.style.top = `${-headerDivEl.current.children.item(0)
				.clientHeight}px`;
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollSticky);

		return () => window.removeEventListener('scroll', scrollSticky);
	}, []);

	return (
		<HeaderDiv ref={headerDivEl}>
			<div className="contribute">
				{/* Please help me keep this resource running with a donation or contribution to{' '}
				<span
					style={{ cursor: 'pointer' }}
					className="pointer"
					data-tip=""
					data-for="wallet"
					onClick={() => {
						changeToolTipText();
					}}
				>
					qtrichierich.eth or 0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f
				</span> */}
				<span style={{ fontWeight: 700, fontSize: '1.4rem', cursor: 'auto' }}>
					Thank you to Non Fungible Labs & Sylo for sponsoring theprawns.xyz and
					keeping this community resource alive.
				</span>
			</div>
			<InfoBar className="InfoBar" />
			<NavWrapper>
				<LogoWrapDiv className="mobile">
					<img src={Prawn} alt="The Prawns" className="footerPrawn" />{' '}
					<Link to="/"> The Prawns </Link>{' '}
				</LogoWrapDiv>
				<MenuButton
					onClick={() => {
						openMenu();
					}}
					role="button"
				>
					Menu
				</MenuButton>
			</NavWrapper>
			<MenuWrapDiv className={menuOpenStatus ? `open` : `closed`}>
				<LogoWrapDiv className="desktop">
					<Link to="/">
						<img src={Prawn} alt="The Prawns" className="footerPrawn" /> The Prawns
					</Link>
				</LogoWrapDiv>
				<MainNavWrap className={menuOpenStatus ? `open` : `closed`}>
					<MenuButton
						className="close"
						onClick={() => {
							closeMenu();
						}}
						role="button"
					>
						Close
					</MenuButton>
					<MainNav>
						{websiteMenuTopNav.map((item) => {
							return (
								<MenuItem
									key={item.name}
									className={`${item.className} MenuItem ${
										item.subMenu && 'has-dropdown'
									}`}
								>
									<CustomNavLink
										onClick={() => {
											closeMenu();
										}}
										to={item.url}
									>
										{item.name}
									</CustomNavLink>
									{item.subMenu && (
										<SubMenuWrap className="subMenu">
											{item.subMenu.map((subMenu) => {
												return (
													<div key={Math.random()}>
														{subMenu.groupTitle && (
															<div className="subMenu-title">{subMenu.groupTitle}</div>
														)}
														<SubMenu className={item.subMenu.length > 1 ? 'text--left' : ''}>
															{subMenu.menuGroup.map((subItem) => {
																return (
																	<SubMenuItem
																		key={subItem.name}
																		className={`${subItem.className} SubMenuItem`}
																	>
																		<CustomSubNavLink
																			onClick={() => {
																				closeMenu();
																			}}
																			to={subItem.url}
																		>
																			{subItem.name}
																		</CustomSubNavLink>
																	</SubMenuItem>
																);
															})}
														</SubMenu>
													</div>
												);
											})}
										</SubMenuWrap>
									)}
								</MenuItem>
							);
						})}
					</MainNav>
				</MainNavWrap>
			</MenuWrapDiv>

			{/* <ReactTooltip
				backgroundColor="black"
				id="wallet"
				getContent={() => toolTipText}
			/> */}
		</HeaderDiv>
	);
}
