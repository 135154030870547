import { initializeApp } from 'firebase/app';
// import { initializeFirestore } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  experimentalForceLongPolling: true
};

// init firebase
const FirebaseApp = initializeApp(firebaseConfig);

//init services
const db = getFirestore(FirebaseApp);

// const db = initializeFirestore(FirebaseApp, {experimentalForceLongPolling: true})

const projectAuth = getAuth();
signInAnonymously(projectAuth)
  .then((res) => {
    // console.log('Logged In')
    // console.log(res)
  })
  .catch((error) => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // console.log('Error: ', errorCode, errorMessage)
    // ...
  });

export {FirebaseApp, db, projectAuth }