import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ThemeDefault from '../../theme/ThemeDefault'
import prawnM4v from '../../videos/prawn-sm.m4v'

const NoMatchDiv = styled.section`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  color: rgb(212, 212, 212);
  opacity: 1;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  margin-top: 0 !important;

  & a {
    color: ${ThemeDefault.white};
    text-decoration: none;
  }
  & h1 {
    font-size: 4rem;
    line-height: 1;
    cursor: pointer;
    z-index: 2;
  }
  
  & h1 span {
    display: block;
    white-space: nowrap;
  }

  .section-inner {
    position: relative;
    z-index: 4;
  }

  .prawn-vid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    opacity: 0.2;
    display: none;
  }

  .prawn-vid.playing {
    display: block;
    opacity: 0.2;
  }

  @media screen and (min-width: 768px) {
    & h1 {
      font-size: 5rem;
    }
  }

  @media screen and (min-width: 992px) {
    &  h1 {
      font-size: 6rem;
    }
  }

  @media screen and (min-width: 1200px) {

    &  h1 {
      font-size: 7rem;
    }
  }
`

export default function NoMatch() {
  return (
    <NoMatchDiv>
      <Link to="/">
        <div className="section-inner">
          <h1>Oops, you seem lost...</h1>
        </div>
        <video muted autoPlay playsInline loop className="prawn-vid playing" title="The PRAWNS" >
          <source src={prawnM4v} type="video/mp4" />
          {/* <source src={prawnWebM} type="video/webm" />
          <source src={prawnOgg} type="video/ogg" />
          <source src={prawnMp4} /> */}
        </video>    
      </Link>
    </NoMatchDiv>
  )
}
  