import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

import { Suspense, lazy } from 'react';
// import './css/autocss.css';
// import './App.css';
// import Home from "./Pages/Home/Home";
// import NothingToSee from "./Pages/NothingToSee/NothingToSee";

// import Collections from "./Collections";
// import FlufWorld from "./Pages/FlufWorld/FlufWorld";
// import FlufItem from "./components/FlufItem/FlufItem";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
// import WhatIsAnNFT from "./Pages/WhatIsAnNFT/WhatIsAnNFT";
// import WhoBehindFlufWorld from "./Pages/WhoBehindFlufWorld/WhoBehindFlufWorld";
// import Metaverse from "./Pages/Metaverse/Metaverse";

import ScrollToTop from './components/ScrollToTop';
// import PostSingle from "./Pages/PostSingle/PostSingle";
import NoMatch from './components/NoMatch/NoMatch';
// import SettingUpMetaMask from "./Pages/SettingUpMetaMask/SettingUpMetaMask";
// import WhoAreThePrawns from "./Pages/WhoAreThePrawns/WhoAreThePrawns";
// import MintingFirstNFT from "./Pages/MintingFirstNFT/MintingFirstNFT";
// import Token from "./components/Token/Token";
import { useEffect } from 'react';
// import BlockchainSecurity from "./Pages/BlockchainSecurity/BlockchainSecurity";
// import Resources from "./Pages/Resources/Resources";
// import Tokens from "./Pages/Tokens/Tokens";
// import Stats from "./Pages/Stats/Stats";
// import MentalHealthAwareness from "./Pages/MentalHealthAwareness/MentalHealthAwareness";
// import TokenAssetToool from "./components/TokenAssetTool/TokenAssetToool";

import { TransitionGroup, CSSTransition } from 'react-transition-group';



// import Posts from "./Pages/Posts/Posts";
// import Timeline from "./components/Timeline/Timeline";

function App() {
	ReactGA.initialize('G-97RV2L277K');

	useEffect(() => {
		let abortController = new AbortController();

		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + window.location.search,
		});

		var EA = document.createComment(`
    ----------------------------------------------------------------------------------------------------\n
    -------------------------FOR----THE----SEEKERS----THAT----DIDN'T----MAKE----IT----------------------\n
    ----------------------------------------------------------------------------------------------------\n
    -----------------------------------------------------------#BGBB#&----------------------------------\n
    -----------------------------#&---------------&&&&&&&----&&#BP5G###---------------------------------\n
    -----------------------------&&&&---------&&#GP5PPGB##B#B###&BB####&--------------------------------\n
    --------------------------------&&&&----&&&&&#&&&&#BB##&&&&##&##&&----------------------------------\n
    ----------------------------------&&&BB#BG##&#BB&BB##&#&&&@&&&&&&-----------------------------------\n
    ------------------------------------&##B####BB&BB&BBGGGBGB#&&&&&&&&&--------------------------------\n
    -------------------------------------&#&#GP555PGGGGPGP55G##&&&&@@&&---------------------------------\n
    ------------------------------------&##GPPP5PGGBB##BBGGP5G#&&&&&@@&---------------------------------\n
    ------------------------------------##GPP5YGBB##G?7JBBBB5PB#&&&&&&&&------&&&-----------------------\n
    -----------------------------------##BGB? - !#&&&7 - - 5&#BGYP##&&-&&&&----&&-##--------------------\n
    ----------------------------------&###B#Y - 7&&&&G - - B&##BJ!?PBB&&&&&-&&&&--##--------------------\n
    --------------------------------&GGB&#### - #&&&&@ - - &&##B#5YPYJP&&&&&---&&-##--------------------\n
    ------------------&#&-----------P?7J5GB#& - @@@@@& - - &&#GY55PGB&@@&&--------&&--------------------\n
    ----------#B##&---#PGB##---------&#BB&BBB#&&&&&&&###BG555P#&&&##&&&---------------------------------\n
    ----------BGB####&&-&&&#&-------###&&&&&###&BBGP55GPPGGB#&&&&&&&B##&--------------------------------\n
    ----------&B#&#BB#######&&&&-&&#GG#@@&&@@&###BBGGGBBG#&&@@&@&@@@&#&-&#&&&---------------------------\n
    ----------&B#&&-&&#####&&-&&&-&&----------#&&&&&#&&&#&@@@-------@&&####&&&&-------------------------\n
    ------------##&##&&&&&&-&&&---------------&@@&&&##&@&&&&@&-----&##B##&&&&&&&#&----------------------\n
    --------------------------------------------&&&&@&&@@@&&-------&##&&&#&&&&#&&B##&-------------------\n
    ---------------------------------------------------------------&&#BPG&-------###&&------------------\n
    ---------------------------------------------------------------&&#BB&-------&&#B#&------------------\n
    ----------------------------------------------------------------&&---------&&&##&&&-----------------\n
    ------------------------------------------------------------------&&---&&&&B####&&------------------\n
    -----------------------------------------------------------------------&&&#G####&-------------------\n
    -------------------------------------------------------------------------&&##&#&--------------------\n
    -------------------------------------------------------------------------#&&###---------------------\n
    ------------------------------------------------------------------------##BGB#&---------------------\n
    ------------------------------------------------------------------------&&&&------------------------\n
    ----------------------------------------------------------------------------------------------------\n
    ----------------------------------------------------------------------------------------------------\n
    `);
		document.head.appendChild(EA);

		return () => abortController.abort();
	}, []);

	const navigate = useNavigate();
	const location = useLocation();

	const isHideHeaderFooter = location.pathname === '/nothing-to-see';

	const pattern = [
		'ArrowUp',
		'ArrowUp',
		'ArrowDown',
		'ArrowDown',
		'ArrowLeft',
		'ArrowRight',
		'ArrowLeft',
		'ArrowRight',
		'b',
		'a',
	];
	let current = 0;
	const keyHandler = function (event) {
		// console.log(event.key)
		// If the key isn't in the pattern, or isn't the current key in the pattern, reset
		if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
			current = 0;
			return;
		}

		// Update how much of the pattern is complete
		current++;

		// If complete, alert and reset
		if (pattern.length === current) {
			current = 0;
			navigate('/nothing-to-see');
		}
	};

	document.addEventListener('keydown', keyHandler, false);

	const Home = lazy(() => import('./Pages/Home/Home'));
	const FlufWorld = lazy(() => import('./Pages/FlufWorld/FlufWorld'));
	const WhatIsAnNFT = lazy(() => import('./Pages/WhatIsAnNFT/WhatIsAnNFT'));
	const BlockchainSecurity = lazy(() =>
		import('./Pages/BlockchainSecurity/BlockchainSecurity'),
	);
	const MintingFirstNFT = lazy(() =>
		import('./Pages/MintingFirstNFT/MintingFirstNFT'),
	);
	const WhoBehindFlufWorld = lazy(() =>
		import('./Pages/WhoBehindFlufWorld/WhoBehindFlufWorld'),
	);
	const Metaverse = lazy(() => import('./Pages/Metaverse/Metaverse'));
	const SettingUpMetaMask = lazy(() =>
		import('./Pages/SettingUpMetaMask/SettingUpMetaMask'),
	);
	const NothingToSee = lazy(() => import('./Pages/NothingToSee/NothingToSee'));

	const Stats = lazy(() => import('./Pages/Stats/Stats'));
	// const FlufGuide = lazy(() => import('./components/FlufGuide/FlufGuide'));

	const Collections = lazy(() => import('./Pages/Collections/Collections'));
	const Tokens = lazy(() => import('./Pages/Tokens/Tokens'));
	const Token = lazy(() => import('./components/Token/Token'));
	const FlufItem = lazy(() => import('./components/FlufItem/FlufItem'));
	const PostSingle = lazy(() => import('./Pages/PostSingle/PostSingle'));
	const Posts = lazy(() => import('./Pages/Posts/Posts'));
	const FoundersInterviews = lazy(() => import('./Pages/FoundersInterviews/FoundersInterviews'));
	const WhoAreThePrawns = lazy(() =>
		import('./Pages/WhoAreThePrawns/WhoAreThePrawns'),
	);
	const TheTeam = lazy(() => import('./Pages/WhoAreThePrawns/TheTeam'));
	const Timeline = lazy(() => import('./components/Timeline/Timeline'));
	// const TimelineChart = lazy(() =>
	// 	import('./components/Timeline/TimelineChart'),
	// );
	const TokenAssetToool = lazy(() =>
		import('./components/TokenAssetTool/TokenAssetToool'),
	);
	const Resources = lazy(() => import('./Pages/Resources/Resources'));

	const MentalHealthAwareness = lazy(() =>
		import('./Pages/MentalHealthAwareness/MentalHealthAwareness'),
	);
	const Terms = lazy(() => import('./Pages/Terms/Terms'));

	const HappyBirthday = lazy(() =>
		import('./components/HappyBirthday/HappyBirthday'),
	);

	const HappyBirthdaySeekers = lazy(() =>
		import('./components/HappyBirthday/HappyBirthdaySeekers'),
	);

	const CalendarComp = lazy(() =>
		import('./components/CalendarComp/CalendarComp'),
	);


	const BrainMap = lazy(() =>
		import('./components/BrainMap/BrainMap'),
	);
	const BrainMapListings = lazy(() =>
		import('./components/BrainMapListings/BrainMapListings'),
	);

	const Newsletters = lazy(() =>
		import('./Pages/Newsletters/Newsletters'),
	);
	
	const TraitFloorMoney = lazy(() =>
		import('./components/TraitFloorMoney/TraitFloorMoney'),
	);
	const ThingieListings = lazy(() =>
		import('./components/ThingieListings/ThingieListings'),
	);

	return (
		<div className="App">
			<Helmet>
				<meta charSet="utf-8" />
				<title>The PRAWNS</title>
				<link rel="canonical" href="https://theprawns.xyz/" />
				<meta
					property="og:description"
					content="The Prawns are a community project & the single point of information from all corners of the Fluf World Ecosystem"
				/>
			</Helmet>
			{!isHideHeaderFooter && <Header />}
			<ScrollToTop>
				<TransitionGroup>
					<CSSTransition key={location.key} classNames="fade" timeout={300}>
						<Suspense fallback={<div>Loading...</div>}>
							<Routes>
								<Route path="/" exact element={<Home />} />
								<Route path="/who-are-we" exact element={<TheTeam />} />
								<Route path="/prawn-history" exact element={<WhoAreThePrawns />} />
								<Route path="/nothing-to-see" exact element={<NothingToSee />} />
								<Route path="/getting-started" exact element={<WhatIsAnNFT />} />
								<Route path="/trait-floor-money" exact element={<TraitFloorMoney />} />
								<Route path="/founders-interviews" exact element={<FoundersInterviews />} />
								<Route
									path="/getting-started/terms-phrases"
									exact
									element={<Terms />}
								/>
								<Route
									path="/getting-started/blockchain-security"
									exact
									element={<BlockchainSecurity />}
								/>
								<Route
									path="/getting-started/what-is-an-nft"
									exact
									element={<WhatIsAnNFT />}
								/>
								<Route
									path="/getting-started/minting-your-first-nft"
									exact
									element={<MintingFirstNFT />}
								/>
								<Route
									path="/getting-started/setting-up-wallet"
									exact
									element={<SettingUpMetaMask />}
								/>
								<Route
									path="/getting-started/what-is-the-metaverse"
									exact
									element={<Metaverse />}
								/>
								<Route
									path="/getting-started/mental-health-awareness"
									exact
									element={<MentalHealthAwareness />}
								/>
								<Route
									path="/the-team-behind-futureverse"
									exact
									element={<WhoBehindFlufWorld />}
								/>
								<Route path="/resources" exact element={<Resources />} />
								<Route path="/brain-map" exact element={<BrainMap />} />
								<Route path="/brain-listings/:type" exact element={<BrainMapListings />} />
								<Route path="/thingie-listings/" exact element={<ThingieListings />} />
								{/* <Route path="/companies" exact element={<Companies />} />
                <Route path="/companies/:id" exact element={<Company />} /> */}
								<Route path="/stats" exact element={<Stats subPage="cards" />} />
								<Route path="/stats/floor" exact element={<Stats subPage="floor" />} />
								<Route
									path="/stats/listings"
									exact
									element={<Stats subPage="listings" />}
								/>
								<Route
									path="/stats/charts"
									exact
									element={<Stats subPage="charts" />}
								/>
								<Route path="/stats/table" exact element={<Stats subPage="table" />} />
								<Route path="/stats/cards" exact element={<Stats subPage="cards" />} />
								<Route
									path="/stats/wallets"
									exact
									element={<Stats subPage="wallets" />}
								/>
								<Route
									path="/stats/custom-wallets"
									exact
									element={<Stats subPage="custom-wallets" />}
								/>
								{/* <Route path="/fluf-guide" exact element={<FlufGuide />} /> */}
								<Route path="/futureverse-guide" exact element={<Collections />} />
								<Route path="/futureverse" exact element={<FlufWorld />} />
								<Route path="/tokens" exact element={<Tokens />} />
								<Route path="/futureverse/:id" exact element={<FlufItem />} />
								<Route path="/futureverse/friends/:id" exact element={<FlufItem />} />
								<Route path="/team/:id" exact element={<FlufItem />} />
								<Route path="/team/friends/:id" exact element={<FlufItem />} />
								<Route path="/post/:id" exact element={<PostSingle />} />
								<Route path="/token/:id" exact element={<Token />} />
								<Route path="/asset" exact element={<TokenAssetToool />} />

								<Route path="/news" exact element={<Posts />} />
								<Route path="/newsletters" exact element={<Newsletters />} />
								<Route path="/futureverse/timeline" exact element={<Timeline />} />
								<Route path="/happy-birthday" exact element={<HappyBirthday />} />
								<Route path="/happy-birthday-seekers" exact element={<HappyBirthdaySeekers />} />
	
								{/* <Route
									path="/futureverse/timeline-chart"
									exact
									element={<TimelineChart />}
								/> */}
								<Route path="/calendar" exact element={<CalendarComp />} />

								<Route path="*" element={<NoMatch />} />
							</Routes>
						</Suspense>
					</CSSTransition>
				</TransitionGroup>
			</ScrollToTop>
			{!isHideHeaderFooter && <Footer />}
		</div>
	);
}

export default App;
