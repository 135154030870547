import styled from 'styled-components';

import Prawn from '../../images/SVG/prawn.svg';
import RDPB from '../../images/richdeane--pb.png';
import Fluffy from '../../images/fluffy.png';

import ShayShow from '../../images/shawShow.png';
import Som from '../../images/somnium.png';
import Stripe from '../../images/stripe.png';

import device from '../../theme/MediaQueries';
import ThemeDefault from '../../theme/ThemeDefault';
import ReactTooltip from 'react-tooltip';
import { useState } from 'react';

const FooterDiv = styled.div`
	padding-top: 0 !important;
	background: #000;
	color: #fff;
	padding-top: 1.2rem;
	padding-bottom: 2.4rem;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 100vh;
	z-index: 1;
	font-size: var(--text-s);
	& .contribute {
		margin-top: -2px;
		padding: 1.2rem;
		margin-bottom: 2.4rem;
		background: ${ThemeDefault.primary};
		width: 100%;
		word-wrap: break-word;
	}
	& .copy-row {
		padding: 0 1.2rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: center;
		@media ${device.MXsm} {
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
		}
	}

	& .footerPB {
		width: 24px;
	}
	& .footerFluf {
		height: 24px;
	}
	& .footerPrawn {
		width: 24px;
	}

	& .contribute span {
		cursor: pointer;
	}
`;
// const FooterDivGrey = styled.div`
//   padding: 1.2rem;
//   background: #100;
//   font-size: var(--text-s);

// `

export default function Footer() {
	const [toolTipText, setToolTipText] = useState(
		'Click To Copy: 0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f',
	);

	const changeToolTipText = () => {
		navigator.clipboard.writeText('0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f');
		setToolTipText('Copied...');
		setTimeout(() => {
			setToolTipText('Click To Copy: 0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f');
		}, 1500);
	};

	return (
		<>
			<FooterDiv>
				<div className="contribute">
					Please help me keep this resource running with a donation or contribution
					to{' '}
					<span
						style={{ cursor: 'pointer' }}
						className="pointer"
						data-tip=""
						data-for="wallet"
						onClick={() => {
							changeToolTipText();
						}}
					>
						qtrichierich.eth or 0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f
					</span>
				</div>
				<div className="owl--xs">
					<div className="disclaimerRow">
						DISCLAIMER: NOT FINANCIAL ADVICE. THIS INFORMATION IS PURELY FOR
						ENTERTAINMENT PURPOSES.
					</div>
					<div className="copy-row">
						<span>Design & Development by&nbsp;</span>
						<span>
							<img src={RDPB} alt="RichieRich" className="footerPB" />
							&nbsp;
						</span>
						<a href="https://www.richdeane.dev" target="_blank" rel="noreferrer">
							Rich Deane (RichieRich)
						</a>
					</div>
					<div className="copy-row">
						<span>Content by&nbsp;</span>
						<span>
							<img src={Fluffy} alt="Fluffy" className="footerFluf" />
							&nbsp;
						</span>
						<a
							href="https://twitter.com/FluffyKiwiNZ"
							target="_blank"
							rel="noreferrer"
						>
							Fluffy Kiwi
						</a>{' '}
						<span>&nbsp;&nbsp;&amp;&nbsp;</span>
						<span>
							<img src={Prawn} alt="RichieRich" className="footerPrawn" />
							&nbsp;
						</span>
						<a
							href="https://twitter.com/theprawns_xyz/"
							target="_blank"
							rel="noreferrer"
						>
							The PRAWNS
						</a>
					</div>
					<div className="copy-row">
						<span>With thanks to &nbsp;</span>
						<span>
							<img src={ShayShow} alt="ShayShow" className="footerFluf" />
							&nbsp;
						</span>
						<a
							href="https://twitter.com/theshaysh0w"
							target="_blank"
							rel="noreferrer"
						>
							@TheShaysh0w
						</a>{' '}
						<span>&nbsp;&nbsp;&amp;&nbsp;</span>
						<span>
							<img src={Som} alt="Somnium Magnus" className="footerFluf" />
							&nbsp;
						</span>
						<a
							href="https://twitter.com/Somnium_Magnus/"
							target="_blank"
							rel="noreferrer"
						>
							@Somnium_Magnus
						</a>{' '}
						<span>&nbsp;&nbsp;&amp;&nbsp;</span>
						<span>
							<img src={Stripe} alt="Str1p3" className="footerFluf" />
							&nbsp;
						</span>
						<a
							href="https://twitter.com/STR1P3_e/"
							target="_blank"
							rel="noreferrer"
						>
							@Str1p3
						</a>
					</div>
					<div className="copy-row">
						<span>Fluf Haus Mix by &nbsp;</span>
						<a
							href="https://twitter.com/0CreditsLeft/"
							target="_blank"
							rel="noreferrer"
						>
							No Credits Left
						</a>
						.<span>&nbsp;&nbsp;&nbsp;</span>
						<span>Fluf Sounds Available On &nbsp;</span>
						<a
							href="https://opensea.io/collection/fluf-world-scenes-and-sounds?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Type&search[stringTraits][0][values][0]=Music/"
							target="_blank"
							rel="noreferrer"
						>
							OpenSea
						</a>
					</div>
					<div className="disclaimerRow" style={{ marginTop: '1.6rem' }}>
						Built By The Community, For The Community.
					</div>
				</div>
				<ReactTooltip
					backgroundColor="black"
					id="wallet"
					getContent={() => toolTipText}
				/>
			</FooterDiv>
		</>
	);
}
