import { useContext, useEffect  } from "react"
import styled from 'styled-components'
import device from "../../theme/MediaQueries";

import { toFixedIfNecessary } from "../helpers";

// import IconAifa from '../../images/SVG/icon-aifa.svg'
// import IconAsm from '../../images/SVG/icon-asm.svg'
// import IconBurrow from '../../images/SVG/icon-burrow.svg'
// import IconFluf from '../../images/SVG/icon-fluf.svg'
// import IconPB from '../../images/SVG/icon-pb.svg'
// import IconSeeker from '../../images/SVG/icon-seeker.svg'
// import IconAsmGenesis from '../../images/SVG/icon-asm-genesis.svg'
import ETH from '../../images/SVG/eth.svg'

import { SiteContext } from "../context/SiteContext";
import { Link } from "react-router-dom";
import ThemeDefault from "../../theme/ThemeDefault";

const FloorPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.2rem;
  width: 100%;
  overflow: hidden;
  & a {
    text-decoration: none;
  }
`

const FloorPriceEl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & div {
    margin: 0 1.2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    /* &.all {
      display: none;
      @media ${device.MXmd} {
        display: block;
      }
    }
    &:not(.all) {
      display: flex;

      @media ${device.MXmd} {
        display: none;
      }
    } */

  }

  & .icon {
    height: 14px;
    width: auto;
    margin-right: 0.4rem;
  }

  & .token:first-of-type {
    border-left: 1px solid ${ThemeDefault.white};
  }

  & .ticker-title {
    font-weight: 400;
    font-size: 1.2rem;
  }
`

export default function FloorPrice({playMarquee}) {

  const {
    projectStatsData,
    collectionsData,
    tokenStatsData,
    tokensData,
    statsDate
  } = useContext(SiteContext)

  
  useEffect(() => {
    let abortController = new AbortController();
    playMarquee(( (projectStatsData.length > 0 && collectionsData.length > 0) && (tokenStatsData.length > 0 && tokensData.length > 0) ));
    return () => abortController.abort();  
  }, [projectStatsData, collectionsData, tokenStatsData, tokensData, playMarquee])


  return (
    <FloorPriceWrapper>
      <Link to="/stats/floor">
        <FloorPriceEl>
          {/* <div className="all">See Floor Prices</div> */}
            {/* .filter(project => project.showInNav === true ) */}
            { ( (projectStatsData.length > 0 && collectionsData.length > 0) && (tokenStatsData.length > 0 && tokensData.length > 0) ) ? 
            <>
            <div className="ticker-title">Projects</div>
            {
              
              projectStatsData
                .filter(p => {
                  return collectionsData.some( project => (project.statsMergeID === p.id && project.showInNav === true) );
                })
                .sort((a,b) => a.order - b.order)
                .map((project) => {

                  const projectID = project.id
                  
                  let proj = collectionsData.filter(collection => collection.statsMergeID === projectID);
                  let col = proj.length > 0 ? proj.reduce(arr => arr) : false;
                  
                  if (col) {
                    return (
                      <div key={col.name} className="project"><span><img src={col.icon} className="icon" alt={`${col.name} Icon`} /></span>{toFixedIfNecessary(project.stats.floor_price, 2)}</div>
                    )
                  } else {
                    return false;
                  }

                }) 
              }
              <div className="spacer">&nbsp;</div>
              <div className="ticker-title">Tokens</div>
              {
                tokenStatsData
                  .sort((a,b) => a.order - b.order)
                  .map((token) => {
                    
                    let tokenStats = tokensData.filter(t => t.contractSymbol === token.symbol);
                    let tok = tokenStats.length > 0 ? tokenStats.reduce(arr => arr) : false;


                    if (tok) {
                      return (
                        <div key={tok.contractSymbol} className="token"><span><img src={tok.icon} className="icon" alt={`${tok.name} Icon`} /></span>{toFixedIfNecessary(token.price, 3)}</div>
                      )
                    } else {
                      return false;
                    }

                  }) 
                }
                <div key={tokenStatsData.filter(tok=>tok.symbol === 'ETH').reduce(arr=>arr).contractSymbol} className="token"><span><img src={ETH} className="icon" alt={`${tokenStatsData.filter(tok=>tok.symbol === 'ETH').reduce(arr=>arr).name} Icon`} /></span>{toFixedIfNecessary(tokenStatsData.filter(tok=>tok.symbol === 'ETH').reduce(arr=>arr).price, 3)}</div>
                <div key='request-date' style={{fontSize: "1.2rem", fontWeight: 400}}>as of {statsDate && statsDate.format("DD MMM YYYY @ HH:mm:ss")}</div>
                </>  
            : <><div>Floor Prices Loading</div></>}
        </FloorPriceEl>
      </Link>
    </FloorPriceWrapper>
  )
}


