import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from "react-router-dom";

import './index.css';
import App from './App';
import { SiteProvider } from './components/context/SiteContext';
import {ErrorBoundary} from 'react-error-boundary'
import Fallback from './components/Fallback';
import ThemeDefault from './theme/ThemeDefault';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './theme/GlobalStyle';
import MusicPlayer from './components/MusicPlayer/MusicPlayer';


ReactDOM.render(
  <>
    <ThemeProvider theme={ThemeDefault}>
      <GlobalStyle />  
      <ErrorBoundary
        FallbackComponent={Fallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <SiteProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SiteProvider>
      </ErrorBoundary>
    </ThemeProvider>
    <MusicPlayer />
  </>,
  document.getElementById('root')
);

// function ErrorFallback({error, resetErrorBoundary}) {
//   return (
//     <div role="alert">
//       <p>Something went wrong:</p>
//       <pre>{error.message}</pre>
//       <button onClick={resetErrorBoundary}>Try again</button>
//     </div>
//   )
// }

