import styled from 'styled-components'
import ThemeDefault from "../../theme/ThemeDefault";
import Marquee from "react-fast-marquee";
import FloorPrice from "./FloorPrice";
import GasPrice from './GasPrice';
import { useState } from 'react';

const NavBarDiv = styled.div`
  background: ${ThemeDefault.black};
  padding: 0.4rem 0;
`

export default function InfoBar() {

  const [playMarquee, setPlayMarquee] = useState(false);

  // const [floorPrices, setFloorPrices] = useState();
  return (
    <NavBarDiv className="grid--2 align-items--center">
      <Marquee delay="2" speed="30" play={playMarquee} gradient={false} pauseOnHover={true} >
        <FloorPrice playMarquee={setPlayMarquee} />
      </Marquee>
      <GasPrice />
    </NavBarDiv>
  )
}
