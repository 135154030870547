
import MontWOFF from "../fonts/mont-regular-webfont.woff";
import MontWOFF2 from "../fonts/mont-regular-webfont.woff2";

import MontBoldWOFF from "../fonts/mont-bold-webfont.woff";
import MontBoldWOFF2 from "../fonts/mont-bold-webfont.woff2";

const Fonts = {
	MontWOFF,
	MontWOFF2,

	MontBoldWOFF,
	MontBoldWOFF2,

}

export default Fonts