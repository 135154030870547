import styled from 'styled-components';
import prawnM4v from '../videos/prawn-sm.m4v'
import ThemeDefault from '../theme/ThemeDefault';

const FallbackDiv = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  color: rgb(212, 212, 212);
  opacity: 1;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  margin-top: 0 !important;

  & a {
    color: ${ThemeDefault.white};
    text-decoration: none;
  }
  & h1 {
    font-size: 4rem;
    line-height: 1;
    cursor: pointer;
    z-index: 2;
  }
  
  & h1 span {
    display: block;
    white-space: nowrap;
  }

  .section-inner {
    position: relative;
    z-index: 4;
  }

  .prawn-vid {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    opacity: 0.2;
    display: none;
  }

  .prawn-vid.playing {
    display: block;
    opacity: 0.2;
  }

  @media screen and (min-width: 768px) {
    & h1 {
      font-size: 5rem;
    }
  }

  @media screen and (min-width: 992px) {
    &  h1 {
      font-size: 6rem;
    }
  }

  @media screen and (min-width: 1200px) {

    &  h1 {
      font-size: 7rem;
    }
  }
  .error-cta {
    width: auto;
    position: relative;
    display: inline-block;
    margin: 0 auto;
    padding: 1.6rem 3.2rem;
    background: ${ThemeDefault.primary};
    color: ${ThemeDefault.white};
    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    font-weight: 700;
    font-size: var(--text-l);
  }
  .error-message {
    width: auto;
    position: relative;
    display: inline-block;
    margin: 0 auto;
    margin-top: 9.6rem;
    padding: 1.6rem 3.2rem;
    background:hsl(0deg 0% 10% / 61%);
    color: ${ThemeDefault.primary};
    font-weight: 700;
    border-radius: 1.6rem;
  }

`

export default function Fallback({error, resetErrorBoundary}) {

  return (
    <FallbackDiv>
      <a href="/">
        <div className="section-inner">
          <h1>Oops, there was an error...</h1>
          <div className="wrapper">
            <div className="error-cta">Head Home...</div>
          </div>
          <div className="wrapper">
            <div className="error-message">{error.message}</div>
          </div>
        </div>
        <video muted autoPlay playsInline loop className="prawn-vid playing" title="The PRAWNS" >
          <source src={prawnM4v} type="video/mp4" />
        </video>    
      </a>
    </FallbackDiv>
  )
}
