import { useCallback, useEffect, useState  } from "react"
import styled from 'styled-components'
import device from "../../theme/MediaQueries";
import ThemeDefault from "../../theme/ThemeDefault";


const GasTrackerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & a {
    text-decoration: none;
  }
`

const GasTracker = styled.div`
  padding: 0 1.2rem;
  width: auto;
  display: flex;
  flex-direction: row;

  & div {
    margin: 0 0.8rem;
    font-size: 1.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.low {
      font-weight: 700;
      color: #32e332;
    }
    &.avg {
      font-weight: 700;
      color: #ff9100;
    }
    &.high {
      font-weight: 700;
      color: #ff0051;
    }

    & span {
      color: ${ThemeDefault.white} !important;
      margin-left: 0.2rem;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1.1px;

      &.mob-show {
        display: none;
        @media ${device.MXmd} {
          display: inline;
        }
      }
      &:not(.mob-show) {
        @media ${device.MXmd} {
          display: none;
        }
      }
    }
  }
`


export default function GasPrice() {

  const [gasPrice, setGasPrice] = useState();

  const fetchData = useCallback(async () => {
    const data = await fetch(`https://api.theprawns.xyz/api/v1/gas`);
    const json = await data.json();

    setGasPrice(json.data);

  }, [])
  
  useEffect(() => {
    // call the function
    let abortController = new AbortController();

    fetchData()
    const gasInterval = setInterval(() => {
      fetchData()
    }, 1000 * 15);

    return () => {
      abortController.abort();  
      clearInterval(gasInterval);
    }
      // make sure to catch any error
  }, [fetchData])

  return (
    <GasTrackerWrapper className="gas-tracker-wrapper ">
      <a href="https://etherscan.io/gastracker" target="_blank" rel="noreferrer">
        <GasTracker className="gas-tracker">
          <div className="title"><span>Current Gas</span><span className="mob-show">Gas</span></div>
          <div className="low">{gasPrice ? gasPrice.SafeGasPrice : '0'}<span>Low</span><span className="mob-show">L</span></div>
          <div className="avg">{gasPrice ? gasPrice.ProposeGasPrice : '0'}<span>Avg</span><span className="mob-show">A</span></div>
          <div className="high">{gasPrice ? gasPrice.FastGasPrice : '0'}<span>High</span><span className="mob-show">H</span></div>
        </GasTracker>
      </a>
    </GasTrackerWrapper>  )
}
