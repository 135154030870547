const websiteMenu = [
	{
		name: 'Home',
		url: '/',
	},

	{
		name: 'News',
		url: '/news',
	},
	{
		name: 'Newsletters',
		url: '/newsletters',
	},
	{
		name: 'Stats',
		url: '/stats/floor',
		subMenu: [
			{
				menuGroup: [
					{
						name: 'Floor Prices',
						url: '/stats/floor',
					},
					{
						name: 'Listings',
						url: '/stats/listings',
					},
					{
						name: 'Cards',
						url: '/stats/cards',
					},
					{
						name: 'Charts',
						url: '/stats/charts',
					},
					{
						name: 'Tables',
						url: '/stats/table',
					},
					{
						name: 'Wallet Counts',
						url: '/stats/wallets',
					},
					{
						name: 'Custom Wallet Counts',
						url: '/stats/custom-wallets',
					},
				],
			},
		],
	},
	{
		name: 'Projects',
		url: '/futureverse',
		subMenu: [
			{
				groupTitle: 'Avatars/Characters',
				menuGroup: [
					{
						name: 'Fluf World',
						url: '/futureverse/fluf-world',
						className: 'core',
					},
					{
						name: 'Party Bear',
						url: '/futureverse/partybear',
						className: 'core',
					},
					{
						name: 'Thingies',
						url: '/futureverse/fluf-world-thingies',
						className: 'core',
					},
					{
						name: 'Seekers',
						url: '/futureverse/fluf-world-seekers',
						className: 'core',
					},
					{
						name: 'Buzzies',
						url: '/futureverse/fluf-world-buzzies',
						className: 'core',
					},
				],
			},
			{
				groupTitle: 'Metaspaces & Accessories',
				menuGroup: [
					{
						name: 'Burrows',
						url: '/futureverse/fluf-world-burrows',
						className: 'core',
					},
					{
						name: 'E.G.G.s',
						url: '/futureverse/fluf-world-eggs',
						className: 'core',
					},
					{
						name: "Dr. G's Ray Guns",
						url: '/futureverse/dr-grordborts-rayguns',
						className: 'core',
					},
					{
						name: 'Scenes & Sounds',
						url: '/futureverse/fluf-world-scenes-and-sounds',
						className: 'core',
					},
					{
						name: 'Fluf Haus',
						url: '/futureverse/fluf-haus',
						className: 'core',
					},
				],
			},
			{
				groupTitle: 'Games & AI',
				menuGroup: [
					{
						name: 'ATEM Car Club',
						url: '/futureverse/atem-car-club',
						className: 'integrations',
					},
					{
						name: 'ASM AIFA Genesis',
						url: '/futureverse/asm-aifa-genesis',
						className: 'integrations',
					},
					{
						name: 'ASM Genesis Brains',
						url: '/futureverse/asm-brains',
						className: 'integrations',
					},
					{
						name: 'ASM AIFA All Stars',
						url: '/futureverse/asm-aifa-all-stars',
						className: 'integrations',
					},
				],
			},
			{
				groupTitle: 'Tokens',
				menuGroup: [
					{
						name: 'All Tokens',
						url: '/tokens/',
						className: 'token',
					},
					{
						name: 'Asto',
						url: '/token/asto',
						className: 'token',
					},
					{
						name: 'Entr',
						url: '/token/entr',
						className: 'token',
					},
					{
						name: 'Sylo',
						url: '/token/sylo',
						className: 'token',
					},
					{
						name: 'XYZ',
						url: '/token/xyz',
						className: 'token',
					},
				],
			},
		],
	},
	{
		name: 'Futureverse Guide',
		url: '/futureverse-guide',
		subMenu: [
			{
				menuGroup: [
					{
						name: 'What Is The Futureverse',
						url: '/futureverse-guide',
					},
					{
						name: 'Futureverse Timeline',
						url: '/futureverse/timeline',
					},
					// {
					// 	name: 'Futureverse Timeline Chart',
					// 	url: '/futureverse/timeline-chart',
					// },
					{
						name: "Who's Behind The Futureverse",
						url: '/the-team-behind-futureverse',
					},
					{
						name: "Founders Interviews",
						url: '/founders-interviews',
					},
				],
			},
		],
	},
	{
		name: 'Events Calendar',
		url: '/calendar',
	},
	{
		name: 'Getting Started',
		url: '/getting-started',
		subMenu: [
			{
				menuGroup: [
					{
						name: 'Common Terms & Phrases',
						url: '/getting-started/terms-phrases',
					},
					{
						name: 'Blockchain Security',
						url: '/getting-started/blockchain-security',
					},
					{
						name: 'What Is An NFT',
						url: '/getting-started/what-is-an-nft',
					},
					{
						name: 'Setting Up A Wallet',
						url: '/getting-started/setting-up-wallet',
					},
					{
						name: 'Minting Your First NFT',
						url: '/getting-started/minting-your-first-nft',
					},
					{
						name: 'What Is The Metaverse',
						url: '/getting-started/what-is-the-metaverse',
					},
					{
						name: 'Mental Health Awareness',
						url: '/getting-started/mental-health-awareness',
					},
				],
			},
		],
	},
	{
		name: 'About Us',
		url: '/who-are-we',
		subMenu: [
			{
				menuGroup: [
					{
						name: 'Who Are We?',
						url: '/who-are-we',
					},
					{
						name: 'The Prawn History',
						url: '/prawn-history',
					},
				],
			},
		],
	},
	{
		name: 'Resources',
		url: '/resources',
		subMenu: [
			{
				menuGroup: [
					{
						name: 'Asset Download',
						url: '/asset',
					},
					// {
					// 	name: 'Vortex Estimate',
					// 	url: '/vortex',
					// },					
					{
						name: 'Brain Mapping',
						url: '/brain-map',
					},
					{
						name: 'Virgin Thingies',
						url: '/thingie-listings',
					},
					// {
					// 	name: 'Gen I Brain Listings',
					// 	url: '/brain-listings/genI',
					// },
					// {
					// 	name: 'Gen II Brain Listings',
					// 	url: '/brain-listings/genII',
					// },
					// {
					// 	name: 'Trait Floor Money',
					// 	url: '/trait-floor-money',
					// },
					{
						name: 'All Resources',
						url: '/resources',
					},
				],
			},
		],
	},
];

export default websiteMenu;
